import * as React from "react";
import { useState, useEffect } from "react";
import { Col, Row, Button, Container, Accordion, Image, Table, Tabs, Tab, Navbar, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "../index.css";
import { ViewAndDownload } from "../Modules/Helper";

export default function FAQ() {
	const [tabKey, setTabKey] = useState<string | undefined>(undefined);
	const [accordionKey, setAccordionKey] = useState<string | undefined>(undefined);

	function scrollToSVGandHighlight(id: string) {
		var e = document.getElementById(id);

		highlightSVG(id);
		if (!checkVisible(e))
			e?.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
	}
	function highlightSVG(id: string) {
		var e = document.getElementById(id);

		e?.setAttribute("style", "transform:scale(3);filter: invert(53%) sepia(61%) saturate(2332%) hue-rotate(339deg) brightness(99%) contrast(103%) ;");

		setTimeout(() => {
			e?.setAttribute("style", "transform:scale(1.5); ");
		}, 2000);
	}

	function scrollToIDandHighlight(id: string) {
		var e = document.getElementById(id);

		highlightID(id);
		if (!checkVisible(e))
			e?.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
	}
	function highlightID(id: string) {
		var e = document.getElementById(id);
		e?.setAttribute("style", "color:#ff6d2d; ");

		setTimeout(() => {
			e?.setAttribute("style", "color:black; ");
		}, 2000);
	}

	function scrollToIDwithOffset(id: string) {
		var e = document.getElementById(id);
		var headerOffset = 100;
		var elementPosition = e?.getBoundingClientRect().top ?? 0;
		var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

		if (!checkVisible(e))
			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
	}

	function checkVisible(elm: any) {
		var rect = elm.getBoundingClientRect();
		var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
		return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
	}

	function handleTabChange(event: any) {
		setTabKey(event);
		if (event === "app") {
			highlightSVG("geluidsinstellingen-a");
			highlightID("row-geluidsinstellingen-a");
		}
		if (event === "app2") {
			highlightSVG("instellingen-a");
			highlightID("row-instellingen-a");
		}
	}

	function handleAccordionChange(event: any) {
		setAccordionKey(event);
	}
	return (
		<>
			<Container className="container-padding-top container-padding-bottom">
				<h1>Support</h1>

				<Tab.Container
					onSelect={(key) => handleTabChange(key)}
					defaultActiveKey=""
					activeKey={tabKey}
				>
					<Row className="faq-menu">
						<Col lg={3}>
							<Nav
								activeKey={tabKey}
								variant="pills"
								className="flex-column faq-menu-list"
							>
								<Nav.Item>
									<Nav.Link
										onClick={() => scrollToIDwithOffset("connect-bluetooth")}
										eventKey="connect-bluetooth"
									>
										Verbinding maken
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link
										onClick={() => scrollToIDwithOffset("checking-battery")}
										eventKey="checking-battery"
									>
										Batterij
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link
										onClick={() => scrollToIDwithOffset("cleaning-eop")}
										eventKey="cleaning-eop"
									>
										Schoonmaken
									</Nav.Link>
								</Nav.Item>

								<Nav.Item onClick={() => scrollToIDwithOffset("app")}>
									<Nav.Link eventKey="app">MyEarsOnly app</Nav.Link>
								</Nav.Item>
								<Nav.Item className="faq-spacer">
									<Nav.Link
										onClick={() => scrollToIDwithOffset("app2")}
										eventKey="app2"
									>
										Instellingen
									</Nav.Link>
								</Nav.Item>
								<Nav.Item className="faq-spacer">
									<Nav.Link
										onClick={() => scrollToIDwithOffset("troubleshooting")}
										eventKey="troubleshooting"
									>
										Probleemoplossing
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link
										onClick={() => scrollToIDwithOffset("downloads")}
										eventKey="downloads"
									>
										Downloads{" "}
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
						<Col lg={9}>
							<Tab.Content>
								<Tab.Pane eventKey={""}>
									<h2
										id="connect-bluetooth"
										className="faq-menu-header"
									>
										Support EarsOnly Protect
									</h2>
									Welkom op de support pagina voor de EarsOnly Protect.
									<br />
									Hier vindt u alle informatie over de EarsOnly Protect en het gebruik hiervan. <br />
									Mocht u er alsnog niet uitkomen neem dan contact op via ons contactformulier.
								</Tab.Pane>

								<Tab.Pane eventKey="connect-bluetooth">
									<h2
										id="connect-bluetooth"
										className="faq-menu-header"
									>
										Hoe koppel ik mijn EarsOnly Protect aan een apparaat?
									</h2>
									<Row>
										<Col>
											<ol>
												<>
													Download de MyEarsOnly app door QR-code hiernaast te scannen of via de{" "}
													<a
														className="faq-link"
														target="_blank"
														href="https://app.earsonly.nl/download"
														rel="noreferrer"
													>
														Google Play Store of Apple App Store.
													</a>{" "}
													Volg de instructies in de app, of koppel EarsOnly Protect handmatig met een apparaat, zoals hieronder uitgelegd:
												</>
												<li>Zorg ervoor dat de Oplaadcase minimaal boven de 25% is opgeladen.</li>
												<small>
													<b>Tip: </b> ga naar{" "}
													<a
														className="faq-link"
														href="#checking-battery"
														onClick={() => handleTabChange("checking-battery")}
													>
														"Hoe controleer ik het batterijniveau en laad ik de batterijen op?"
													</a>{" "}
													voor hulp om op te laden en om het batterijniveau te controleren.{" "}
												</small>
												<li>Zorg ervoor dat de Earbuds goed in de Oplaadcase zijn geplaatst.</li>
												<li>Houd de knop van de Oplaadcase voor 3 seconden ingedrukt. Wacht tot alle drie de lampjes knipperen (dit kan tot 10 seconden duren).</li>
												<li>
													Open het Bluetooth menu van je apparaat en druk op “Verbind nieuw apparaat” (dit kan verschillen per apparaat, raadpleeg de bijbehorende
													handleiding).
												</li>
												<li>Als alle drie de lampjes van de Oplaadcase knipperen, zal “EarsOnly Protect” in deze lijst verschijnen.</li>
												<li>Druk op “EarsOnly Protect” om te verbinden.</li>
											</ol>
										</Col>
										<Col lg={4}>
											<Row>
												<Col
													xs={5}
													lg={10}
												>
													<Image
														fluid
														src="images/faq/scan_QRcode.svg"
													/>
												</Col>
												<Col
													xs={5}
													lg={10}
												>
													<Image
														fluid
														src="images/faq/pairing.svg"
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Tab.Pane>

								<Tab.Pane eventKey="checking-battery">
									<h2
										id="checking-battery"
										className="faq-menu-header"
									>
										Hoe controleer ik het batterijniveau en laad ik de batterijen op?
									</h2>
									<Row>
										<Col>
											<ol>
												<Row>
													<Col className="faq-battery-text">
														<br />
														<li>Open het deksel van de Oplaadcase.</li>
														<li>Controleer de batterijstatus van de Oplaadcase, welke kan worden afgelezen aan het middelste lampje (Afbeelding 1).</li>
														<li>
															Wanneer de batterij leeg is, kan de Oplaadcase worden aangesloten met behulp van de meegeleverde USB-C kabel en een USB-lader of USB-poort.
															De Oplaadcase kan ook draadloos worden opgeladen op een draadloze lader.
														</li>
													</Col>
													<Col
														lg={4}
														className="faq-battery-image"
													>
														<Image
															src="images/faq/case_battery_light.svg"
															fluid
														/>
														<Col className="faq-battery-image-title">
															<small>Afbeelding 1</small>
														</Col>
													</Col>
												</Row>
												<Row>
													<Col className="faq-battery-text">
														<li>
															Plaats de Earbud (links of rechts) op de overeenkomstige oplaadcontacten, zoals weergegeven in Afbeelding 2. <br />
															<small>
																<b> Waarschuwing:</b> Zorg ervoor dat de oplaadcontacten van zowel de Earbuds als de Oplaadcase vrij zijn van vuil (zie:{" "}
																<a
																	className="faq-link"
																	href="#cleaning-eop"
																	onClick={() => handleTabChange("cleaning-eop")}
																>
																	{" "}
																	"Hoe maak ik mijn EarsOnly Protect schoon?"
																</a>
																). Als er slecht of geen contact is, beginnen de Earbuds niet met laden.
															</small>
														</li>
														<li>
															Als de Earbud in de Oplaadcase wordt geplaatst, gaat het bijbehorende lampje voor links of rechts (Afbeelding 2) kort branden om aan te
															geven dat er goed contact is.
														</li>
														<li>Herhaal voor de andere Earbud.</li>
													</Col>

													<Col
														lg={4}
														className="faq-battery-image"
													>
														<Image
															src="images/faq/right_earbud_in_case.svg"
															fluid
														/>
														<Col className="faq-battery-image-title">
															<small>Afbeelding 2</small>
														</Col>
													</Col>
												</Row>
												<Row>
													<Col className="faq-battery-text">
														<br />
														<li>Sluit het deksel van de Oplaadcase</li>
														<small>
															<b>Optioneel:</b> Druk kort op de knop in van de Oplaadcase (afbeelding 3) om de huidige batterijstatus van de Earbuds en de Oplaadcase te
															bekijken. Na een paar seconden gaan de lampjes weer uit om energie te besparen.
														</small>
													</Col>
													<Col
														lg={4}
														className="faq-battery-image"
													>
														<Image
															src="images/faq/pairing.svg"
															fluid
														/>
														<Col className="faq-battery-image-title">
															<small>Afbeelding 3</small>
														</Col>
													</Col>
												</Row>
											</ol>
										</Col>

										<Row>
											<Col
												lg={4}
												className="faq-battery-image"
											>
												<Image
													src="images/faq/earbuds_in_case.svg"
													fluid
												/>
												<Col className="faq-battery-image-title">
													<small>Afbeelding 4</small>
												</Col>
											</Col>
											<Col>
												<Table
													striped
													bordered
													size="sm"
												>
													<thead>
														<tr>
															<th>
																Lampje 1,2,3
																<p className="fw-normal">Afbeelding 4</p>
															</th>
															<th className="">
																Omschrijving <br />
																<br />
															</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Aan </td>
															<td>Earbud/Oplaadcase is meer dan 25% opgeladen </td>
														</tr>
														<tr>
															<td className="text-nowrap">Aan (Oranje)</td>
															<td>Earbud/Oplaadcase is minder dan 25% opgeladen </td>
														</tr>
														<tr>
															<td>Aan (Rood)</td>
															<td>Earbud/Oplaadcase is minder dan 10% opgeladen </td>
														</tr>
														<tr>
															<td>Uit </td>
															<td>
																Oplaadcase batterij is leeg, of er is geen verbinding tussen Earbud(s) en Oplaadcase oplaadcontacten (de lampjes gaan na een aantal
																seconden uit om batterij te besparen).
															</td>
														</tr>
														<tr>
															<td>Fade animatie</td>
															<td>Oplaadcase is aan het opladen (wanneer het deksel dicht is, gaan de lampjes na een aantal seconden uit).</td>
														</tr>
													</tbody>
												</Table>
												<small>Tabel 1: Batterijniveau</small>
											</Col>
										</Row>
									</Row>
								</Tab.Pane>

								<Tab.Pane eventKey="cleaning-eop">
									<h2
										id="cleaning-eop"
										className="faq-menu-header"
									>
										Hoe maak ik mijn EarsOnly Protect schoon?
									</h2>
									<p>
										<i>
											<b> Waarschuwing:</b> Gebruik geen agressieve schoonmaakmiddelen of oplosmiddelen, zoals benzeen, verdunner, afwasmiddel of alcohol, omdat deze het
											oppervlak kunnen beschadigen. Neem contact op met de fabrikant voor aanvullende informatie.
										</i>
									</p>
									<Row
										xs={1}
										md={3}
									>
										<Col>
											<h5>Earbuds</h5>
											Maak met een licht vochtigde doek de Earbuds aan de buitenkant schoon. Zorg ervoor dat ook de oplaadcontacten vrij van vuil zijn.
											<Image
												src="images/faq/cleaning_earbuds.svg"
												fluid
											/>
										</Col>
										<Col>
											<h5>Oplaadcase</h5>
											Maak met een licht vochtigde doek de binnen- en buitenkant van de Oplaadcase schoon. Zorg ervoor dat ook de oplaadcontacten vrij van vuil zijn.
											<Image
												src="images/faq/cleaning_case.svg"
												fluid
											/>
										</Col>
										<Col>
											<h5>Cerumenfilters</h5>
											Periodieke vervanging van de cerumenfilters (Wax guards) is vereist om een correcte werking te garanderen. Neem contact op met uw leidinggevende of
											wederverkoper als de oorsmeerfilters sterk vervuild of beschadigd zijn.
											<Image
												src="images/faq/cleaning_filter.svg"
												fluid
											/>
										</Col>
									</Row>
									<Row>
										<p>
											<i>
												<b>Let op:</b> Zorg dat de Earbuds en Oplaadcase volledig droog zijn voordat u de Earbuds in de Oplaadcase plaatst.
											</i>
										</p>
									</Row>
								</Tab.Pane>

								<Tab.Pane eventKey="app">
									<h2
										id="app"
										className="faq-menu-header"
									>
										MyEarsOnly app
									</h2>
									<Row>
										<Col id="explain">
											<Row
												className="pointer"
												id="row-geluidsinstellingen-a"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-a");
													highlightID("row-geluidsinstellingen-a");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/A.svg" />
												</Col>
												<Col xs={8}>
													<p>
														<b>Connectie </b>
														Laat zien of je Earbuds of Oplaadcase verbonden zijn met je apparaat.
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-b"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-b");
													highlightID("row-geluidsinstellingen-b");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/B.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Batterij status </b>
														Laat de batterij status zien van de Earbuds en de Oplaadcase.
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-microphone"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-microphone");
													highlightID("row-geluidsinstellingen-microphone");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/Microphone.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Microfoon </b>
														Laat zien welke microfoon wordt gebruikt bij een telefoongesprek.
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-charging"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-charging");
													highlightID("row-geluidsinstellingen-charging");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/Charging.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Opladen </b>
														Laat zien of de Earbud of Oplaadcase aan het opladen is.
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-c"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-c");
													highlightID("row-geluidsinstellingen-c");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/C.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Percentage </b>
														Batterijpercentage van de Earbud of Oplaadcase
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-d"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-d");
													highlightID("row-geluidsinstellingen-d");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/D.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Geluid/Instellingen </b>
														Wissel tussen geluidsinstellingen en gebruikersinstellingen
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-e"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-e");
													highlightID("row-geluidsinstellingen-e");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/E.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Gehoorbescherming </b>
														Wissel tussen de verschillende gehoorbeschermingsmodi
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-f"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-f");
													highlightID("row-geluidsinstellingen-f");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/F.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Audiobalans </b>
														Pas aan en verfijn de audiobalans in elk oor
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-geluidsinstellingen-g"
												onClick={() => {
													scrollToSVGandHighlight("geluidsinstellingen-g");
													highlightID("row-geluidsinstellingen-g");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/G.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Muziek equalizer </b>
														Kies een audioprofiel dat past bij je favoriete muziekgenre
													</p>
												</Col>
											</Row>
										</Col>

										<Col className="app-image-instellingen">
											<Image
												src="images/icons/A.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-a");
													highlightSVG("geluidsinstellingen-a");
												}}
												id="geluidsinstellingen-a"
												className="pointer geluidsinstellingen-a"
											/>
											<Image
												src="images/icons/B.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-b");
													highlightSVG("geluidsinstellingen-b");
												}}
												id="geluidsinstellingen-b"
												className="pointer geluidsinstellingen-b"
											/>
											<Image
												src="images/icons/Microphone.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-microphone");
													highlightSVG("geluidsinstellingen-microphone");
												}}
												id="geluidsinstellingen-microphone"
												className="pointer geluidsinstellingen-microphone"
											/>
											<Image
												src="images/icons/Charging.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-charging");
													highlightSVG("geluidsinstellingen-charging");
												}}
												id="geluidsinstellingen-charging"
												className="pointer geluidsinstellingen-charging"
											/>

											<Image
												src="images/icons/C.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-c");
													highlightSVG("geluidsinstellingen-c");
												}}
												id="geluidsinstellingen-c"
												className="pointer geluidsinstellingen-c"
											/>
											<Image
												src="images/icons/D.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-d");
													highlightSVG("geluidsinstellingen-d");
												}}
												id="geluidsinstellingen-d"
												className="pointer geluidsinstellingen-d"
											/>
											<Image
												src="images/icons/E.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-e");
													highlightSVG("geluidsinstellingen-e");
												}}
												id="geluidsinstellingen-e"
												className="pointer geluidsinstellingen-e"
											/>
											<Image
												src="images/icons/F.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-f");
													highlightSVG("geluidsinstellingen-f");
												}}
												id="geluidsinstellingen-f"
												className="pointer geluidsinstellingen-f"
											/>
											<Image
												src="images/icons/G.svg"
												onClick={() => {
													scrollToIDandHighlight("row-geluidsinstellingen-g");
													highlightSVG("geluidsinstellingen-g");
												}}
												id="geluidsinstellingen-g"
												className="pointer geluidsinstellingen-g"
											/>

											<Image src="/images/faq/app-geluidsinstellingen.svg" />
										</Col>
									</Row>
								</Tab.Pane>
								<Tab.Pane eventKey="app2">
									<h2
										id="app2"
										className="faq-menu-header"
									>
										App instellingen
									</h2>
									<Row>
										<Col id="explain">
											<Row
												className="pointer"
												id="row-instellingen-a"
												onClick={() => {
													scrollToSVGandHighlight("instellingen-a");
													highlightID("row-instellingen-a");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/A.svg" />
												</Col>
												<Col xs={8}>
													<p>
														<b>Knopbediening </b>
														Pas de functies aan die zijn toegewezen aan de tap- en drukknoppen van elke Earbud.
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-instellingen-b"
												onClick={() => {
													scrollToSVGandHighlight("instellingen-b");
													highlightID("row-instellingen-b");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/B.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Software-update </b>
														Ophalen en installeren van updates.
													</p>
												</Col>
											</Row>

											<Row
												className="pointer"
												id="row-instellingen-c"
												onClick={() => {
													scrollToSVGandHighlight("instellingen-c");
													highlightID("row-instellingen-c");
												}}
											>
												<Col xs={1}>
													<Image src="images/icons/C.svg" />
												</Col>
												<Col xs={10}>
													<p>
														<b>Hulp en veelgestelde vragen</b>
													</p>
												</Col>
											</Row>
										</Col>

										<Col className="app-image-instellingen">
											<Image
												src="images/icons/A.svg"
												onClick={() => {
													scrollToIDandHighlight("row-instellingen-a");
													highlightSVG("instellingen-a");
												}}
												id="instellingen-a"
												className="pointer instellingen-a"
											/>
											<Image
												onClick={() => {
													scrollToIDandHighlight("row-instellingen-b");
													highlightSVG("instellingen-b");
												}}
												src="images/icons/B.svg"
												id="instellingen-b"
												className="pointer instellingen-b"
											/>
											<Image
												onClick={() => {
													scrollToIDandHighlight("row-instellingen-c");
													highlightSVG("instellingen-c--------------------------------------------------");
												}}
												src="images/icons/C.svg"
												id="instellingen-c"
												className="pointer instellingen-c"
											/>

											<Image src="/images/faq/app-instellingen.svg" />
										</Col>
									</Row>
								</Tab.Pane>

								<Tab.Pane eventKey="troubleshooting">
									<h2
										id="troubleshooting"
										className="faq-menu-header"
									>
										Probleemoplossing
									</h2>
									<Accordion
										flush
										activeKey={accordionKey}
										onSelect={(key) => handleAccordionChange(key)}
										defaultActiveKey={"1"}
									>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<b>Reset Earbuds & Reset Oplaadcase</b>
											</Accordion.Header>
											<Accordion.Body>
												<b>Reset Earbuds</b>
												<ol>
													<li>Haal de Earbuds uit de Oplaadcase.</li>
													<li>Houd de knoppen van de Earbuds voor 15 seconden ingedrukt.</li>
													<li>
														Plaats de Earbuds terug in de Oplaadcase en wacht 10 seconden.
														<br />
														<p>
															<i>
																<b>Let op:</b> Er worden geen (voorkeurs)instellingen gewijzigd.
															</i>
														</p>
													</li>
												</ol>
												<b>Reset Oplaadcase</b>
												<ol>
													<li>Houd de knop van de Oplaadcase voor 15 seconden ingedrukt.</li>
												</ol>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<b>Na lang opladen zijn mijn Earbuds niet opgeladen</b>
											</Accordion.Header>
											<Accordion.Body>
												<ol>
													<li>Zorg ervoor dat de Earbuds goed in de Oplaadcase zijn geplaatst.</li>
													<li>
														Zorg ervoor dat de Oplaadcase is opgeladen (zie:{" "}
														<a
															className="faq-link"
															href="#checking-battery"
															onClick={() => handleTabChange("checking-battery")}
														>
															“Hoe controleer ik het batterijniveau en laad ik de batterijen op?”
														</a>
														)
													</li>
													<li>
														Maak zowel de oplaadcontacten van de Earbuds als de Oplaadcase schoon met een licht vochtige doek. Plaats de Earbuds terug in de Oplaadcase en
														controleer of de lampjes oplichten.
													</li>
													<li>
														Mocht dit niet werken, voer dan een reset van de Earbuds en Oplaadcase uit en probeer opnieuw (zie:{" "}
														<a
															href="#1"
															onClick={() => handleAccordionChange("1")}
															className="faq-link"
														>
															{" "}
															“Reset Earbuds & Reset Oplaadcase”
														</a>
														)
													</li>
												</ol>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<b>Mijn Earbuds spelen geen audio af</b>
											</Accordion.Header>
											<Accordion.Body>
												<ol>
													<li>Zorg dat de Earbuds verbonden zijn met de audiobron.</li>
													<li>Zorg ervoor dat er audio wordt afgespeeld.</li>
													<li>Plaats de Earbuds in de Oplaadcase, wacht 10 seconden en pak ze weer uit de Oplaadcase.</li>
													<li>
														Nog steeds geen audio? Voer een reset van de Earbuds uit en probeer opnieuw (zie:{" "}
														<a
															href="#1"
															onClick={() => handleAccordionChange("1")}
															className="faq-link"
														>
															{" "}
															“Reset Earbuds”
														</a>
														).
													</li>
												</ol>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="4">
											<Accordion.Header>
												<b>Mijn Earbuds kunnen niet gekoppeld worden met mijn apparaat</b>
											</Accordion.Header>
											<Accordion.Body>
												<ol>
													<li>Zorg ervoor dat de Oplaadcase minimaal boven de 25% is opgeladen.</li>
													<li>Zorg ervoor dat de Earbuds goed in de Oplaadcase zijn geplaatst.</li>
													<li>
														Controleer of beide Earbuds met de Oplaadcase zijn verbonden. Druk op de knop van de Oplaadcase. Controleer of alle drie de lampjes oplichten.
													</li>
													<ol>
														<li>
															Als alle lampjes oplichten, volg dan de koppelinstructies (zie:{" "}
															<a
																onClick={() => handleTabChange("connect-bluetooth")}
																href="#connect-bluetooth"
																className="faq-link"
															>
																"Hoe koppel ik mijn EarsOnly Protect aan een apparaat?"
															</a>
															).
														</li>
														<li>
															Als er een lampje niet oplicht, heeft de bijbehorende Earbud geen verbinding met de Oplaadcase. Koppelen zal dan niet lukken: Maak zowel de
															oplaadcontacten van de Earbuds als de Oplaadcase schoon met een licht vochtige doek. Plaats de Earbuds terug in de Oplaadcase en controleer
															of de lampjes oplichten. Als alle lampjes oplichten, volg dan de koppelinstructie
														</li>
													</ol>
													<li>
														Mocht dit niet werken, voer dan een reset van de Earbuds en Oplaadcase uit en probeer opnieuw (zie:{" "}
														<a
															href="#1"
															onClick={() => handleAccordionChange("1")}
															className="faq-link"
														>
															{" "}
															“Reset Earbuds & Reset Oplaadcase”
														</a>
														)
													</li>
												</ol>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="5">
											<Accordion.Header>
												<b>Mijn Earbuds maken geen verbinding met mijn apparaat</b>
											</Accordion.Header>
											<Accordion.Body>
												<ol>
													<li>Plaats de Earbuds in de Oplaadcase, wacht 10 seconden en haal ze er weer uit.</li>
													<li>
														Controleer of de Earbuds gekoppeld zijn aan je apparaat. Als dit niet het geval is volg dan de koppelinstructies (zie:{" "}
														<a
															onClick={() => handleTabChange("connect-bluetooth")}
															href="#connect-bluetooth"
															className="faq-link"
														>
															"Hoe koppel ik mijn EarsOnly Protect aan een apparaat?"
														</a>
														).
													</li>
													<li>
														Mocht dit niet werken, voer dan een reset van de Earbuds uit en probeer opnieuw (zie:{" "}
														<a
															href="#1"
															onClick={() => handleAccordionChange("1")}
															className="faq-link"
														>
															{" "}
															“Reset Earbuds”
														</a>
														).
													</li>
													<li>Start het apparaat waarmee je probeert te verbinden opnieuw op en probeer opnieuw.</li>
													<li>
														Mocht dit ook niet werken, verwijder dan de Earbuds uit de Bluetooth- koppelingslijst op het apparaat en koppel de Earbuds opnieuw (zie:{" "}
														<a
															onClick={() => handleTabChange("connect-bluetooth")}
															href="#connect-bluetooth"
															className="faq-link"
														>
															"Hoe koppel ik mijn EarsOnly Protect aan een apparaat?"
														</a>
														).
													</li>
												</ol>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="6">
											<Accordion.Header>
												<b>Audio loopt niet synchroon</b>
											</Accordion.Header>
											<Accordion.Body>
												<ol>
													<li>Plaats de Earbuds in de Oplaadcase, wacht 10 seconden en haal ze er weer uit.</li>
													<li>
														Mocht dit niet werken, voer dan een reset van de Earbuds uit en probeer opnieuw (zie:{" "}
														<a
															href="#1"
															onClick={() => handleAccordionChange("1")}
															className="faq-link"
														>
															{" "}
															“Reset Earbuds”
														</a>
														).
													</li>
												</ol>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="7">
											<Accordion.Header>
												<b>Terug naar de fabrieksinstellingen</b>
											</Accordion.Header>
											<Accordion.Body>
												<p>
													<i>
														<b>Waarschuwing:</b> Door terug te gaan naar fabrieksinstellingen verlies je de (voorkeurs)instellingen en de koppelingsinformatie met je
														apparaten.
													</i>
												</p>
												<ol>
													<li>Zorg ervoor dat de Oplaadcase minimaal boven de 25% is opgeladen.</li>
													<li>Zorg ervoor dat de Earbuds goed in de Oplaadcase zijn geplaatst.</li>
													<li>Houd de knop van de Oplaadcase voor 3 seconden ingedrukt. Wacht tot alle drie de lampjes knipperen, dit kan tot 10 seconden duren.</li>
													<li>Druk twee keer snel achter elkaar op de knop van de Oplaadcase. De lampjes zouden nu in een andere kleur moeten knipperen.</li>
													<li>
														Zodra de lampjes weer in de oranje kleur knipperen zijn de instellingen terug naar de fabrieksinstellingen. Je kan nu jouw EarsOnly Protect
														opnieuw koppelen aan een apparaat.
													</li>
												</ol>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Tab.Pane>
								<Tab.Pane eventKey="downloads">
									<h2
										id="downloads"
										className="faq-menu-header"
									>
										Downloads
									</h2>
									<h3>Nederlands</h3>
									<hr />

									<ViewAndDownload
										title="Snel Start Gids"
										path="/documents/downloads/2024-03-04_SnelStartGids_007561-R1B.pdf"
									/>
									<ViewAndDownload
										title="Referentiegids"
										path="/documents/downloads/2024-02-26_ReferenceGuide_007562-R1B-NL.pdf"
									/>
									<ViewAndDownload
										title="Veelgestelde vragen en Probleemoplossing"
										path="/documents/downloads/2024-06-27_EarsOnly Protect-FAQ+Troubleshooting-NL.pdf"
									/>

									<br />
									<br />
									<br />
									<h3>English</h3>
									<hr />

									<ViewAndDownload
										title="Quick Start Guide"
										path="/documents/downloads/2024-02-19_QuickStart_007570_R1B.pdf"
									/>
									<ViewAndDownload
										title="Reference Guide"
										path="/documents/downloads/2024-02-26_ReferenceGuide_007562-R1B-EN.pdf"
									/>
									<ViewAndDownload
										title="Frequently asked questions and Troubleshooting"
										path="/documents/downloads/2024-06-10_EarsOnly_Protect-FAQ+Troubleshooting-EN.pdf"
									/>
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</Container>
		</>
	);
}

